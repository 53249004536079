import React from "react"
import { Container as ChakraContainer } from "@chakra-ui/react"

export default function Container({ children, isNarrow = false, ...props }) {
  return (
    <ChakraContainer
      maxW={
        isNarrow
          ? [null, "28em", "32em", "48em", "62em"]
          : [null, "30em", "48em", "62em", "80em"]
      }
      px="2"
      {...props}
    >
      {children}
    </ChakraContainer>
  )
}
