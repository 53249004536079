/**
 * Take a normalised category object and output the props
 * for use in a Tag (background color)
 */

export default function tagProps(category) {
  const tagProps = {}
  if (category.color === "blue") {
    tagProps.bg = "#805ad5"
  }
  if (category.color === "blue-dark") {
    tagProps.colorScheme = "blue"
  }
  if (category.color === "black") {
    tagProps.bg = "black"
  }
  if (category.color_custom) {
    tagProps.bg = category.color_custom
  }
  return tagProps
}
