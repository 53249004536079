import React, {
  useRef,
  useState,
  forwardRef,
  useEffect,
  useCallback,
} from "react"
import {
  Box,
  Stack,
  Input,
  HStack,
  Kbd,
  Fade,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Heading,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import Container from "@components/Container"
import Logo from "../assets/svg/logo.inline.svg"
import PostItem from "@components/PostItem"
import MeiliSearch from "meilisearch"

import DarkModeToggle from "react-dark-mode-toggle"

export default function Header() {
  const headerRef = useRef()

  const [headerHeight, setHeaderHeight] = useState()

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight)
  }, [])

  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <>
      <Box
        as="header"
        py="5"
        bg={colorMode === "light" ? "blue.500" : "blue.900"}
        ref={headerRef}
      >
        <Container>
          <Stack isInline spacing="10" alignItems="center">
            <Box maxW="3rem">
              <Link to="/">
                <Box as={Logo} maxW="100%" h="auto" />
              </Link>
            </Box>
            <Box flexGrow="1">
              <SearchInput headerHeight={headerHeight} />
            </Box>
            <Box flexGrow="1" mx="auto" />
            <Box display={["none", "none", "none", "block"]}>
              <HStack alignItems="center" spacing="5">
                {/* <MenuSecondary /> */}
                <DarkModeToggle
                  onChange={toggleColorMode}
                  checked={colorMode !== "light"}
                  size={80}
                  size="4rem"
                />
              </HStack>
            </Box>

            <Box display={[null, null, null, "none"]}>{/* Right */}</Box>
          </Stack>
        </Container>
      </Box>
    </>
  )
}

function useRefWithCallback(cb = null) {
  const ref = useRef()
  const setRef = useCallback(
    (node) => {
      cb(node, ref.current)

      ref.current = node
    },
    [cb]
  )
  return [ref, setRef]
}

const SearchInput = forwardRef(function SearchInput(
  { headerHeight, ...props },
  ref
) {
  const [hasFocus, setHasFocus] = useState()
  const inputRef = useRef()
  const {
    isOpen: isSearchOpen,
    onOpen: onSearchOpen,
    onClose: onSearchClose,
  } = useDisclosure()
  const [modalContentRef, setModalContentRef] = useRefWithCallback((node) => {
    if (node) {
      //.chakra-modal__content-container
      node.parentElement.style.pointerEvents = "none"
    }

    if (!searchClient.current) {
      searchClient.current = new MeiliSearch({
        host: `https://${process.env.GATSBY_MEILISEARCH_URL}`,
        apiKey: process.env.GATSBY_MEILI_PUBLIC_KEY,
      })
      getSearchIndex()
    }
  })
  const [searchResults, setSearchResults] = useState([])
  const searchClient = useRef()
  const postsIndex = useRef()

  function handleRef(r) {
    if (typeof ref !== "undefined") {
      ref = r
    }
    inputRef.current = r
  }

  useEffect(() => {
    window.addEventListener("keyup", handleKeyPress)
    return () => {
      window.removeEventListener("keyup", handleKeyPress)
    }
  }, [])

  function handleKeyPress(e) {
    if (e.key === "/") console.log(document.activeElement)
    if (
      e.key === "/" &&
      // (document.activeElement.matches("#gatsby-focus-wrapper") ||
      // document.activeElement === document.body)
      document.activeElement.nodeName !== "INPUT"
    ) {
      inputRef.current.focus()
    }
  }

  function handleChange(e) {
    if (e.target.value && !isSearchOpen) {
      onSearchOpen()
    }
  }

  const getSearchResults = async (searchQuery) => {
    // console.log(`searching: ${searchQuery}`)
    if (!postsIndex.current) return

    try {
      const results = await postsIndex.current.search(searchQuery)
      setSearchResults(results)
      // console.log({ results })
    } catch (e) {
      console.error(e)
    }
  }

  const getSearchIndex = async () => {
    try {
      postsIndex.current = await searchClient.current.getIndex("posts")
    } catch (e) {
      console.error(e)
    }
  }

  function handleKeyup(e) {
    if (e.key === "Escape") {
      if (isSearchOpen) {
        onSearchClose()
      } else {
        inputRef.current.value = ""
        inputRef.current.blur()
      }
    } else {
      if (e.target.value?.length > 2) getSearchResults(e.target.value)
    }
  }

  const searchBgColor = useColorModeValue("blue.600", "blue.800")

  return (
    <>
      <Box position="relative" maxWidth="20rem" w="100%" minWidth="200px">
        <Input
          ref={handleRef}
          onFocus={(e) => {
            if (e.target.value) {
              onSearchOpen()
            }
            setHasFocus(true)
          }}
          onBlur={() => {
            setHasFocus(false)
          }}
          bg={"transparent"}
          color="white"
          _focus={{
            boxShadow: "0 0 0 1px orange.700",
            bg: searchBgColor,
          }}
          aria-label="Search"
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === "Tab" && e.target.value && modalContentRef.current) {
              modalContentRef.current.focus()
            }
          }}
          onKeyUp={handleKeyup}
          {...props}
        />
        <Box
          position="absolute"
          display="flex"
          alignItems="center"
          px="4"
          top="0"
          left="0"
          height="100%"
          color="blue.200"
          pointerEvents="none"
        >
          <Fade in={!hasFocus && !inputRef?.current?.value}>
            <Box as="span" alignItems="center">
              Search...{" "}
              <Kbd
                color="gray.600"
                ml="2"
                borderBottomColor="gray.300"
                position="relative"
                top="-2px"
                bg="blue.100"
              >
                /
              </Kbd>
            </Box>
          </Fade>
        </Box>
      </Box>
      <Modal
        onClose={onSearchClose}
        size={"full"}
        isOpen={isSearchOpen}
        initialFocusRef={inputRef.current}
        trapFocus={false}
        closeOnOverlayClick={false}
      >
        <ModalContent
          mt={`${headerHeight || 0}px`}
          mb="0"
          boxShadow="none"
          ref={setModalContentRef}
          pointerEvents="all"
        >
          <ModalHeader aria-label="Search results">
            <Box maxW="xl" mx="auto">
              <Heading as="h2" fontSize="1.5rem" fontWeight="bold">
                Results
              </Heading>
            </Box>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody maxH="100%" overflowY="auto" pb="8">
            <Box maxW="xl" mx="auto" py="20">
              <VStack spacing="2" align="start">
                {searchResults?.hits?.map((post, index) => (
                  <PostItem
                    post={post}
                    key={`${post.slug}-${index}`}
                    showImage={index === 0}
                  />
                ))}
              </VStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
})
