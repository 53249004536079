import React from "react"
import Helmet from "react-helmet"

const defaultDescription = ""
const defaultOGURL = `https://${process.env.GATSBY_SITE_URL}`
// const defaultOGImage = ""

const titleAppend = " | NimbleWebDeveloper"

export default function SEO({
  description = defaultDescription,
  title = "",
  url = defaultOGURL,
  // ogImage = defaultOGImage,
  ogImage = null,
}) {
  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <title>{`${title}${titleAppend}`}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
    <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
    <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
    <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
    <link
      rel="apple-touch-icon"
      sizes="114x114"
      href="/apple-icon-114x114.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href="/apple-icon-120x120.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="144x144"
      href="/apple-icon-144x144.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href="/apple-icon-152x152.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/apple-icon-180x180.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href="/android-icon-192x192.png"
    />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/manifest.json" /> */}
      {/* <meta name="msapplication-TileColor" content="#434190" /> */}
      {/* <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" /> */}
      {/* <meta name="theme-color" content="#434190" /> */}
      {/*<link rel="icon" sizes="192x192" href="/static/touch-icon.png" />
    <link rel="apple-touch-icon" href="/static/touch-icon.png" />
    <link rel="mask-icon" href="/static/favicon-mask.svg" color="#49B882" />
    <link rel="icon" href="/static/favicon.ico" />*/}
      <meta property="og:url" content={url || defaultOGURL} />
      <meta property="og:title" content={title || ""} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta name="twitter:site" content={url || defaultOGURL} />
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:image" content={ogImage || defaultOGImage} /> */}
      {/* <meta property="og:image" content={ogImage || defaultOGImage} /> */}

      {/* {ogImage && (
        <>
          <meta
            property="og:image"
            content={`https://${process.env.GATSBY_SITE_URL}/${ogImage.path}`}
          />
          <meta property="og:image:width" content={ogImage.size.width} />
          <meta property="og:image:height" content={ogImage.size.height} />
        </>
      )} */}
    </Helmet>
  )
}
