import React, { useEffect, useState } from "react"

import {
  Box,
  Heading,
  VStack,
  AspectRatio,
  Avatar,
  Flex,
  Text,
  Tag,
  Wrap,
  WrapItem,
  Link as ChakraLink,
  VisuallyHidden,
  useColorModeValue,
} from "@chakra-ui/react"
import { Link, navigate } from "gatsby"
import Img from "gatsby-image"
import tagProps from "@utils/tagProps"

const dayjs = require("dayjs")
var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

export default function PostItem({ post, showImage = false }) {
  const postMetaTextColor = useColorModeValue("gray.700", "gray.300")
  const postBorderColor = useColorModeValue("gray.300", "gray.700")

  const [postPublishedAtFromNow, setPostPublishedAtFromNow] = useState()
  useEffect(() => {
    setPostPublishedAtFromNow(dayjs(post?.publishedAt).fromNow())
  }, [])

  return (
    <Box position="relative" minW="100%">
      <ChakraLink
        as={Link}
        aria-labelledby={`post-link__${post.slug}`}
        pointerEvents="none"
        position="absolute"
        left="0"
        top="0"
        width="100%"
        height="100%"
        opacity="0"
        to={`/blog/${post.slug}`}
        _focus={{ boxShadow: "outline", opacity: 1 }}
        rounded="md"
      >
        <VisuallyHidden>{post.title}</VisuallyHidden>
      </ChakraLink>
      <Box
        role="presentation"
        overflow="hidden"
        border="1px solid"
        borderColor={postBorderColor}
      >
        {!!showImage && !!post?.mainImage?.fluid && (
          <Link to={`/blog/${post.slug}`}>
            <AspectRatio as="span" ratio={16 / 8} display="block">
              <Box>
                <Img
                  fluid={post.mainImage.fluid}
                  alt={post?.mainImage?.alt}
                  objectFit="cover"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
            </AspectRatio>
          </Link>
        )}
        <Box
          px="4"
          py="4"
          cursor="pointer"
          onClick={(e) => {
            if (e.target.href) {
              return
            }
            navigate(`/blog/${post.slug}`)
          }}
        >
          <VStack align="start" spacing="4">
            <Flex color={postMetaTextColor} minW="100%">
              <Box w="2.5rem" mr="2">
                <Avatar
                  bg="blue.900"
                  name={post.author.name}
                  size="sm"
                  w="2.5rem"
                  h="2.5rem"
                  src={post.author.image.fixed.src}
                />
              </Box>
              <Box flexGrow="1">
                <Text fontWeight="bold">{post.author.name}</Text>
                <Text fontSize="sm">
                  {post?.publishedAtFormat} ({postPublishedAtFromNow})
                </Text>
              </Box>
              <Box alignSelf="flex-end">
                <Text fontSize="sm">{post?.readingTime?.text}</Text>
              </Box>
            </Flex>
            <Box minW="100%">
              <Link to={`/blog/${post.slug}`} id={`post-link__${post.slug}`}>
                <Heading as="h3" size="lg" fontWeight="bold">
                  {post.title}
                </Heading>
              </Link>
            </Box>
            <Box minW="100%">
              <p>{post.excerpt}</p>
            </Box>
            <Box minW="100%">
              {!!post?.categories?.length && (
                <Wrap>
                  {post.categories?.map((category, categoryIndex) => (
                    <WrapItem key={categoryIndex}>
                      <Tag
                        as={Link}
                        to={`/category/${category.slug}`}
                        size={"md"}
                        variant="solid"
                        colorScheme={category.color}
                        rounded="full"
                        {...tagProps(category)}
                      >
                        {category.title}
                      </Tag>
                    </WrapItem>
                  ))}
                </Wrap>
              )}
            </Box>
          </VStack>
        </Box>
      </Box>
    </Box>
  )
}
