const dataNormaliser = {
  normaliseCategory: function (c) {
    return {
      ...c,
      title: c?.title,
      slug: c?.slug?.current,
      color: c?.color,
      color_custom: c?.color_custom?.hex,
      posts: c?.posts ? c?.posts.map(dataNormaliser.normalisePost) : null,
    }
  },

  normalisePost: function (post) {
    return {
      ...post,
      title: post?.title,
      author: {
        name: post?.author?.name,
        image: {
          fixed: post?.author?.image?.asset?.localFile?.childImageSharp?.fixed,
        },
      },
      categories: post?.categories?.map(dataNormaliser.normaliseCategory) || [],
      slug: post?.slug?.current,
      publishedAtFormat: post?.publishedAtFormat,
      publishedAtFromNow: post?.publishedAtFromNow,
      mainImage: {
        alt: post?.mainImage?.alt,
        fluid: post?.mainImage?.asset?.localFile?.childImageSharp?.fluid,
      },
      body: post?.body || null,
      related: post?.related
        ? post.related.map(dataNormaliser.normalisePost)
        : [],
    }
  },
}
module.exports = dataNormaliser
