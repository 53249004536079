import React from "react"
// import { Box, Grid } from "@chakra-ui/react"
import Header from "@components/Header"
import Container from "@components/Container"

const Layout = ({ children }) => (
  <div>
    <Header />
    <Container>{children}</Container>
  </div>
)

export default Layout
